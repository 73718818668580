import React, { FC } from "react";
import { Pages } from "../../lib/pages";
import styles from "./link.module.scss";
import SingleLink from "./singleLink";
import { useTranslations } from "next-intl";

const MultiLinks: FC = () => {
	const t = useTranslations();
	return (
		<div className={styles.shbar}>
			<SingleLink text={t("about")} link={Pages.About} icon="MdInfo" />
			<SingleLink text={t("play")} link={Pages.Game} icon="MdPlayCircleFilled" />
			<SingleLink text={t("for-parents")} link={Pages.Groups} icon="MdPeopleAlt" />
		</div>
	);
};

export default MultiLinks;
