import type { GetStaticProps, NextPage } from "next";
import styles from "../styles/index.module.scss";
import { Center } from "../components/basic";
import { Logo } from "../components/images";
import { MainLinks } from "../components/mainlinks";
import { getTranslations } from "../lib/translations";

const Index: NextPage = () => (
	<Center>
		<div>
			<Center>
				<div className={styles.logo}>
					<Logo />
				</div>
			</Center>
			<Center>
				<MainLinks />
			</Center>
		</div>
	</Center>
);

export const getStaticProps: GetStaticProps = async ({ locale }) => {
	const messages = await getTranslations(locale, ["index", "common"]);
	return {
		props: {
			messages,
		},
	};
};

export default Index;
