import React, { FC } from "react";
import Link from "next-translate-routes/link";
import styles from "./link.module.scss";
import { MdPlayCircleFilled, MdInfo, MdPeopleAlt } from "react-icons/md";
import { Center } from "../basic";

type SingleLinkProps = {
	text: string;
	link: string;
	icon: Icons;
};

export type Icons = "MdPlayCircleFilled" | "MdInfo" | "MdPeopleAlt";

const SingleLink: FC<SingleLinkProps> = ({ text, link, icon }) => (
	<Link href={link} passHref>
		<a>
			<span className={styles.mdi}>
				<Center>
					{icon === "MdPlayCircleFilled" ? (
						<MdPlayCircleFilled color="#ffffff" className={styles.play} />
					) : icon === "MdInfo" ? (
						<MdInfo color="#ffffff" className={styles.play} />
					) : (
						<MdPeopleAlt color="#ffffff" className={styles.play} />
					)}
				</Center>
			</span>
			<span className={styles.text}>{text}</span>
		</a>
	</Link>
);

export default SingleLink;
